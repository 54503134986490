import * as React from 'react'
import { TEAM } from '@voltus/constants'
import { RouteConfig } from '@voltus/types'

interface Routes {
  routes: Array<RouteConfig>
  initRoutes: () => void
}

export const routes: Routes = {
  routes: [],
  initRoutes: function (this: Routes) {
    const Index = React.lazy(
      () => import(/* webpackChunkName: "profile" */ './Login')
    )

    this.routes = [
      {
        team: TEAM.FRONTEND,
        path: '*',
        Component: Index,
        Wrapper: React.Fragment,
      },
    ]
  },
}
